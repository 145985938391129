import { Button, chakra, Flex, Heading, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { Routes } from '../lib/routes';
import { todoistColor } from '../styles/theme';

export const LandingPageHeader = () => (
	<chakra.header width="100%">
		<Flex alignItems="center" justifyContent="space-between">
			<div>
				<Heading as="h1" size="md" color={todoistColor} fontWeight="900">
					REVIEW APP FOR TODOIST
				</Heading>
				<Text color="#aaa" fontSize="sm" fontWeight={500}>
					by{' '}
					<a href="https://www.monolog.tech/" target="_blank">
						monolog.tech
					</a>
				</Text>
			</div>
			<div>
				<Link href={Routes.login()} passHref>
					<Button bg={todoistColor} as="div" colorScheme="red">
						Log in with Todoist
					</Button>
				</Link>
			</div>
		</Flex>
	</chakra.header>
);
