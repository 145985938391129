import { NextPage } from 'next';
import {
	Box,
	Center,
	Container,
	Heading,
	VStack,
	Text,
	Grid,
	GridItem,
	Button,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Link from 'next/link';

import { LandingPageHeader } from '../components/landing-page-header';
import { todoistColor } from '../styles/theme';
import { Routes } from '../lib/routes';

const Home: NextPage = () => {
	return (
		<Box overflow="hidden" p={4} bg="#f5f5f5" height="100vh">
			<Grid gridTemplateRows="40px 1fr">
				<GridItem>
					<LandingPageHeader />
				</GridItem>
				<GridItem height="calc(100vh - 40px)">
					<Container width="80ch" height="100%">
						<Center width="100%" height="calc(100% - 4rem)">
							<VStack>
								<Heading
									as={motion.h1}
									whiteSpace="nowrap"
									initial={{
										color: '#000',
										fontSize: `var(--chakra-fontSizes-4xl)`,
										y: 0,
									}}
									animate={{
										color: '#c4c4c4',
										fontSize: `var(--chakra-fontSizes-3xl)`,
										y: -10,
										transition: {
											delay: 1.5,
											duration: 1,
										},
									}}
								>
									Tired of browsing{' '}
									<Text
										as={motion.span}
										initial={{
											color: todoistColor,
										}}
										animate={{
											color: `${todoistColor}55`,
											transition: {
												delay: 1.5,
												duration: 1,
											},
										}}
									>
										Todoist Activity Log
									</Text>
									?
								</Heading>
								<Text
									as={motion.p}
									mt={8}
									fontSize="4xl"
									whiteSpace="nowrap"
									fontWeight="bold"
									initial={{
										y: 0,
										color: '#bbb',
									}}
									animate={{
										y: -10,
										color: '#000',
										transition: {
											delay: 1.5,
											duration: 1,
										},
									}}
								>
									Discover new way to review your work organised way
								</Text>
								<Box
									as={motion.div}
									initial={{
										opacity: 0,
									}}
									animate={{
										opacity: 1,
										transition: {
											delay: 2.5,
											duration: 0.7,
										},
									}}
								>
									<Link href={Routes.login()} passHref>
										<Button bg={todoistColor} as="div" colorScheme="red">
											Log in with Todoist
										</Button>
									</Link>
								</Box>
							</VStack>
						</Center>
						<Text color="gray" height="4rem">
							This application is not created by, affiliated with, or supported
							by Doist
						</Text>
					</Container>
				</GridItem>
			</Grid>
		</Box>
	);
};

export default Home;
